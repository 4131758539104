import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import {
  GetPoolMessagesDto,
  GetPublicTalentPoolDataDto,
  PoolTalentLog,
  TalentMessage,
  TalentPoolTalent,
  TalentPoolTeamComment,
  TeamComment,
} from "~/models/types";
import TokenHelpers from "~/helpers/tokenHelpers";
import TalentPoolService from "~/services/talentPoolService";
import {
  authStore,
  companyBaseStore,
  jobbOfferStore,
  talentPoolStore,
} from "~/utils/store-accessor";
import SignalRHelper from "~/helpers/SignalRHelper";
import TalentPoolHelpers from "~/helpers/TalentPoolHelpers";
import jobOfferService from "~/services/jobOfferService";
import FilterHelpers from "~/helpers/filterHelpers";
import TalentHelpers from "~/helpers/talentHelpers";

type PoolTeamCommentsForTalent = {
  talentId: string;
} & (
  | {
      type: "Loading";
    }
  | {
      type: "Loaded";
      comments: TalentPoolTeamComment[];
    }
);

export type MessageForRecTalentInPool = {
  talentId: string;
  recruitmentId: string;
} & (
  | {
      type: "Loading";
    }
  | {
      type: "Loaded";
      comments: TalentMessage[];
    }
);

type TeamCommentsForRecTalentInPool = {
  talentId: string;
  recruitmentId: string;
} & (
  | {
      type: "Loading";
    }
  | {
      type: "Loaded";
      comments: TeamComment[];
    }
);

type PoolMessageInternal = {
  talentId: string;
} & (
  | {
      type: "Loading";
    }
  | {
      type: "Loaded";
      messages: GetPoolMessagesDto[];
    }
);

@Module({
  name: "talentPool",
  stateFactory: true,
  namespaced: true,
})
export default class TalentPoolStore extends VuexModule {
  poolMessagesByCompanyId: Record<string, GetPoolMessagesDto[]> = {};
  get poolMessagesByTalentId() {
    return this.poolMessagesInternal.reduce(
      (acc: Record<string, GetPoolMessagesDto[]>, m) => {
        if (m.type !== "Loaded") {
          return acc;
        }
        return {
          ...acc,
          [m.talentId]: m.messages,
        };
      },
      {}
    );
  }

  poolMessagesInternal: PoolMessageInternal[] = [];
  poolTalentsInternal: TalentPoolTalent[] = [];

  showAllTalents = false;

  @Mutation
  SET_SHOW_ALL_TALENTS(v: boolean) {
    this.showAllTalents = v;
  }

  get poolTalents(): TalentPoolTalent[] {
    return this.showAllTalents
      ? companyBaseStore.companyTalents.flatMap(ct => {
          if (!ct.nonAnonymouseProfileDetails) {
            return [];
          }
          return TalentHelpers.mapToPoolTalent(ct);
        })
      : this.poolTalentsInternal;
  }

  poolTalentsLoaded = false;

  @Mutation
  SET_POOL_MESSAGES_INTERNAL(v: PoolMessageInternal[]) {
    this.poolMessagesInternal = v;
  }

  @Mutation
  SET_POOL_MESSAGES_BY_COMPANY_ID(v: {
    messages: GetPoolMessagesDto[];
    companyId: string;
  }) {
    this.poolMessagesByCompanyId = {
      ...this.poolMessagesByCompanyId,
      [v.companyId]: v.messages,
    };
  }

  @Mutation
  SET_POOL_TALENTS_LOADED(v: boolean) {
    this.poolTalentsLoaded = v;
  }

  @Mutation
  SET_POOL_TALENTS(v: TalentPoolTalent[]) {
    this.poolTalentsInternal = v;
  }

  @Action
  async updateRatingOnPoolTalent(v: {
    rating: number | null;
    talentId: string;
  }) {
    const token = await TokenHelpers.getToken();

    await TalentPoolService.updateRatingOnPoolTalent({
      accessToken: token,
      rating: v.rating,
      talentId: v.talentId,
    });

    talentPoolStore.SET_POOL_TALENTS(
      this.poolTalentsInternal.map(t => {
        if (t.talentId === v.talentId) {
          return {
            ...t,
            rating: v.rating,
          };
        }

        return t;
      })
    );
  }

  publicPoolData: GetPublicTalentPoolDataDto | null = null;

  @Mutation
  SET_PUBLIC_POOL_DATA(v: GetPublicTalentPoolDataDto | null) {
    this.publicPoolData = v;
  }

  @Action
  async loadPublicTalentPoolData(v: { token: string }) {
    const result = await TalentPoolService.getPublicTalentPoolData({
      accessToken: v.token,
    });

    talentPoolStore.SET_PUBLIC_POOL_DATA(result);
  }

  poolTalentLogs: PoolTalentLog[] = [];
  @Mutation
  SET_POOL_TALENT_LOGS(v: PoolTalentLog[]) {
    this.poolTalentLogs = v;
  }

  @Action
  async loadLogsForPoolTalent(v: { talentId: string }) {
    const token = await TokenHelpers.getToken();
    const logs = await TalentPoolService.GetPoolTalentLogs({
      accessToken: token,
      talentId: v.talentId,
    });
    talentPoolStore.SET_POOL_TALENT_LOGS(logs);
  }

  @Action
  async acceptPublicPool(v: { token: string }) {
    await TalentPoolService.acceptPublicTalentPool({
      accessToken: v.token,
    });

    talentPoolStore.SET_PUBLIC_POOL_DATA(
      this.publicPoolData
        ? {
            ...this.publicPoolData,
            talentPool: {
              ...this.publicPoolData.talentPool,
              accepted: new Date(),
              declined: null,
            },
          }
        : null
    );
  }

  @Action
  async declinePublicPool(v: { token: string }) {
    await TalentPoolService.declinePublicTalentPool({
      accessToken: v.token,
    });

    talentPoolStore.SET_PUBLIC_POOL_DATA(
      this.publicPoolData
        ? {
            ...this.publicPoolData,
            talentPool: {
              ...this.publicPoolData.talentPool,
              declined: new Date(),
            },
          }
        : null
    );
  }

  @Action
  async updateTalentContactInfoFromPublicPool(v: {
    token: string;
    contactEmail: string | null;
    phoneNumber: string | null;
  }) {
    await TalentPoolService.updateTalentContactInfoFromPublicPool({
      accessToken: v.token,
      contactEmail: v.contactEmail,
      phoneNumber: v.phoneNumber,
    });

    talentPoolStore.SET_PUBLIC_POOL_DATA(
      this.publicPoolData
        ? {
            ...this.publicPoolData,
            email: v.contactEmail,
            phoneNumber: v.phoneNumber,
          }
        : null
    );
  }

  @Action
  async addTalentToPool(v: {
    message: string | null;
    talentId: string;
    removeFromRecruitmentId: string | null;
  }) {
    const token = await TokenHelpers.getToken();

    const newTalent = await TalentPoolService.addTalentToPoolFromCompany({
      accessToken: token,
      message: v.message,
      talentId: v.talentId,
      removeFromRecruitmentId: v.removeFromRecruitmentId,
    });

    talentPoolStore.SET_POOL_TALENTS(
      SignalRHelper.getListWithNewItem({
        items: this.poolTalentsInternal,
        newItem: newTalent,
        getId: x => x.id,
      })
    );

    if (v.removeFromRecruitmentId !== null) {
      jobbOfferStore.removeTalentFromRecruitment({
        talentId: v.talentId,
        recruitmentId: v.removeFromRecruitmentId,
      });
    }
  }

  @Action
  async removeTalentFromPool(v: { talentId: string; message: string | null }) {
    const token = await TokenHelpers.getToken();

    await TalentPoolService.removeTalentFromPool({
      accessToken: token,
      talentId: v.talentId,
      message: v.message,
    });

    talentPoolStore.SET_POOL_TALENTS(
      this.poolTalentsInternal.map(pt => {
        if (pt.talentId === v.talentId) {
          return {
            ...pt,
            removed: new Date(),
          };
        }
        return pt;
      })
    );
  }

  canViewRecTalentTeamCommentsInPool: boolean = false;

  @Mutation
  SET_CAN_VIEW_REC_TALENT_TEAM_COMMENTS_IN_POOL(v: boolean) {
    this.canViewRecTalentTeamCommentsInPool = v;
  }

  teamCommentsByRecTalent: TeamCommentsForRecTalentInPool[] = [];

  get teamCommentsByRecTalentMap(): Map<
    string,
    TeamCommentsForRecTalentInPool
  > {
    return this.teamCommentsByRecTalent.reduce(
      (acc: Map<string, TeamCommentsForRecTalentInPool>, x) => {
        acc.set(TalentPoolHelpers.getRecTalentKey(x), x);
        return acc;
      },
      new Map<string, TeamCommentsForRecTalentInPool>()
    );
  }

  @Mutation
  SET_TEAM_COMMENTS_BY_REC_TALENT(v: TeamCommentsForRecTalentInPool[]) {
    this.teamCommentsByRecTalent = v;
  }

  @Action
  async loadTeamCommentsByRecTalent(v: {
    talentId: string;
    recruitmentId: string;
  }) {
    const loadedComments = this.teamCommentsByRecTalentMap.get(
      TalentPoolHelpers.getRecTalentKey(v)
    );

    if (loadedComments === undefined) {
      talentPoolStore.SET_TEAM_COMMENTS_BY_REC_TALENT(
        SignalRHelper.getListWithNewItem({
          items: this.teamCommentsByRecTalent,
          getId: x => TalentPoolHelpers.getRecTalentKey(x),
          newItem: {
            type: "Loading",
            recruitmentId: v.recruitmentId,
            talentId: v.talentId,
          },
        })
      );

      const token = await TokenHelpers.getToken();
      const messages = await jobOfferService.getTeamCommentsOnTalent(
        v.recruitmentId,
        v.talentId,
        token
      );

      await jobbOfferStore.loadCompanyTeamMembers();

      talentPoolStore.SET_TEAM_COMMENTS_BY_REC_TALENT(
        SignalRHelper.getListWithNewItem({
          items: this.teamCommentsByRecTalent,
          getId: x => TalentPoolHelpers.getRecTalentKey(x),
          newItem: {
            type: "Loaded",
            comments: messages
              .map(c => {
                return {
                  ...c,
                  sharedToEmails: c.sharedToEmails ?? [],
                  sender: FilterHelpers.getCommentSender({
                    c,
                    companyCommentSenders: jobbOfferStore.companyCommentSenders,
                  }),
                };
              })
              .filter(c => !!c.sender),
            recruitmentId: v.recruitmentId,
            talentId: v.talentId,
          },
        })
      );
    }
  }

  @Action
  async loadPoolTalents() {
    if (!this.poolTalentsLoaded) {
      const token = await TokenHelpers.getToken();

      const poolTalents = await TalentPoolService.getPoolTalentsForCompany({
        accessToken: token,
      });

      talentPoolStore.SET_POOL_TALENTS(poolTalents);
      talentPoolStore.SET_POOL_TALENTS_LOADED(true);
    }
  }

  canViewRecTalentMessagesInPool: boolean = false;

  @Mutation
  SET_CAN_VIEW_REC_TALENT_MESSAGES_IN_POOL(v: boolean) {
    this.canViewRecTalentMessagesInPool = v;
  }

  messagesByRecTalent: MessageForRecTalentInPool[] = [];

  get messagesByRecTalentMap(): Map<string, MessageForRecTalentInPool> {
    return this.messagesByRecTalent.reduce(
      (acc: Map<string, MessageForRecTalentInPool>, x) => {
        acc.set(TalentPoolHelpers.getRecTalentKey(x), x);
        return acc;
      },
      new Map<string, MessageForRecTalentInPool>()
    );
  }

  get poolMessagesByTalentIdMap(): Map<string, PoolMessageInternal> {
    return this.poolMessagesInternal.reduce(
      (acc: Map<string, PoolMessageInternal>, x) => {
        acc.set(x.talentId, x);
        return acc;
      },
      new Map<string, PoolMessageInternal>()
    );
  }

  @Mutation
  SET_MESSAGE_BY_REC_TALENT(v: MessageForRecTalentInPool[]) {
    this.messagesByRecTalent = v;
  }

  @Action
  async loadMessagesByRecTalent(v: {
    talentId: string;
    recruitmentId: string;
    force?: boolean;
  }) {
    const loadedComments = this.messagesByRecTalentMap.get(
      TalentPoolHelpers.getRecTalentKey(v)
    );

    if (v.force || loadedComments === undefined) {
      talentPoolStore.SET_MESSAGE_BY_REC_TALENT(
        SignalRHelper.getListWithNewItem({
          items: this.messagesByRecTalent,
          getId: x => TalentPoolHelpers.getRecTalentKey(x),
          newItem: {
            type: "Loading",
            recruitmentId: v.recruitmentId,
            talentId: v.talentId,
          },
        })
      );

      const token = await TokenHelpers.getToken();
      const messages = await jobOfferService.getTalentMessages(
        v.talentId,
        v.recruitmentId,
        token
      );

      talentPoolStore.SET_MESSAGE_BY_REC_TALENT(
        SignalRHelper.getListWithNewItem({
          items: this.messagesByRecTalent,
          getId: x => TalentPoolHelpers.getRecTalentKey(x),
          newItem: {
            type: "Loaded",
            comments: messages,
            recruitmentId: v.recruitmentId,
            talentId: v.talentId,
          },
        })
      );
    }
  }

  poolTeamComments: PoolTeamCommentsForTalent[] = [];

  @Mutation
  SET_POOL_TEAM_COMMENTS(v: PoolTeamCommentsForTalent[]) {
    this.poolTeamComments = v;
  }

  @Action
  async loadPoolTeamComments(v: { talentId: string }) {
    const loadedComments = this.poolTeamComments.find(
      x => x.talentId === v.talentId
    );
    if (!loadedComments) {
      talentPoolStore.SET_POOL_TEAM_COMMENTS(
        SignalRHelper.getListWithNewItem({
          items: this.poolTeamComments,
          getId: x => x.talentId,
          newItem: {
            type: "Loading",
            talentId: v.talentId,
          },
        })
      );

      const token = await TokenHelpers.getToken();

      const comments = await TalentPoolService.getPoolTeamCommentsForTalentId({
        accessToken: token,
        talentId: v.talentId,
      });

      talentPoolStore.SET_POOL_TEAM_COMMENTS(
        SignalRHelper.getListWithNewItem({
          items: this.poolTeamComments,
          getId: x => x.talentId,
          newItem: {
            type: "Loaded",
            comments,
            talentId: v.talentId,
          },
        })
      );
    }
  }

  @Action
  async addPoolTeamComment(v: {
    commentText: string;
    talentId: string;
    mentionIds: string[];
    companyId: string;
    userId: string;
  }) {
    const token = await TokenHelpers.getToken();

    const response = await TalentPoolService.addPoolTeamComment({
      accessToken: token,
      commentText: v.commentText,
      mentionIds: v.mentionIds,
      talentId: v.talentId,
    });

    const newComment: TalentPoolTeamComment = {
      commentText: v.commentText,
      companyId: v.companyId,
      date: new Date(),
      id: response.commentId,
      mentionIds: v.mentionIds,
      senderUserId: v.userId,
      talentId: v.talentId,
    };

    let existingComments: TalentPoolTeamComment[] = [];

    const existingTalent = this.poolTeamComments.find(
      x => x.talentId === v.talentId
    );

    if (existingTalent?.type === "Loaded") {
      existingComments = existingTalent.comments;
    }

    talentPoolStore.SET_POOL_TEAM_COMMENTS(
      SignalRHelper.getListWithNewItem({
        items: talentPoolStore.poolTeamComments,
        getId: c => c.talentId,
        newItem: {
          type: "Loaded",
          talentId: v.talentId,
          comments: [...existingComments, newComment],
        },
      })
    );
  }

  @Action
  async addPoolMessageFromCompany(v: {
    messageText: string;
    talentId: string;
  }) {
    const token = await TokenHelpers.getToken();
    const newId = await TalentPoolService.addPoolMessageFromCompany({
      accessToken: token,
      messageText: v.messageText,
      talentId: v.talentId,
    });

    await jobbOfferStore.loadCompanyTeamMembers();

    const senderMember =
      jobbOfferStore.companyCommentSenders[authStore.userId!];

    if (senderMember) {
      const messagesObj = this.poolMessagesByTalentIdMap.get(v.talentId);

      const messages =
        messagesObj?.type === "Loaded" ? messagesObj.messages : [];

      talentPoolStore.SET_POOL_MESSAGES_INTERNAL(
        SignalRHelper.getListWithNewItem({
          items: this.poolMessagesInternal,
          getId: x => x.talentId,
          newItem: {
            type: "Loaded",
            talentId: v.talentId,
            messages: [
              ...messages,
              {
                type: "FromCompany",
                companyId: authStore.companyId!,
                talentId: v.talentId,
                companySender: senderMember,
                date: new Date(),
                id: newId,
                messageText: v.messageText,
              },
            ],
          },
        })
      );
    }
  }

  @Action
  async addPoolMessageFromTalent(v: {
    messageText: string;
    companyId: string;
  }) {
    const token = await TokenHelpers.getToken();
    const newId = await TalentPoolService.addPoolMessageFromTalent({
      accessToken: token,
      companyId: v.companyId,
      messageText: v.messageText,
    });

    talentPoolStore.SET_POOL_MESSAGES_BY_COMPANY_ID({
      messages: [
        ...(this.poolMessagesByCompanyId[v.companyId] ?? []),
        {
          type: "FromTalent",
          companyId: v.companyId,
          talentId: authStore.userId!,
          date: new Date(),
          id: newId,
          messageText: v.messageText,
        },
      ],
      companyId: v.companyId,
    });
  }

  @Action
  async loadPoolMessagesForCompany(v: { talentId: string }) {
    if (this.poolMessagesByTalentIdMap.get(v.talentId) === undefined) {
      talentPoolStore.SET_POOL_MESSAGES_INTERNAL(
        SignalRHelper.getListWithNewItem({
          items: this.poolMessagesInternal,
          getId: x => x.talentId,
          newItem: {
            type: "Loading",
            talentId: v.talentId,
          },
        })
      );

      const token = await TokenHelpers.getToken();

      const messages = await TalentPoolService.getPoolMessagesForCompany({
        accessToken: token,
        talentId: v.talentId,
      });

      talentPoolStore.SET_POOL_MESSAGES_INTERNAL(
        SignalRHelper.getListWithNewItem({
          items: this.poolMessagesInternal,
          getId: x => x.talentId,
          newItem: {
            type: "Loaded",
            messages,
            talentId: v.talentId,
          },
        })
      );
    }
  }

  @Action
  async loadPoolMessagesForTalent(v: { companyId: string }) {
    if (this.poolMessagesByCompanyId[v.companyId] === undefined) {
      const token = await TokenHelpers.getToken();

      const messages = await TalentPoolService.getPoolMessagesForTalent({
        accessToken: token,
        companyId: v.companyId,
      });

      talentPoolStore.SET_POOL_MESSAGES_BY_COMPANY_ID({
        messages,
        companyId: v.companyId,
      });
    }
  }
}
