import { arraysAreEqual } from "./CommonHelperFunctions";
import {
  JobOfferRequirementType,
  GetTalentsForSourcingRequirementDto,
  GetTalentsForSourcingRequirementGroupDto,
  GetTalentsForSourcingDto,
} from "~/models/types";

export default class JobbOfferRequirementsHelper {
  static oldSearchCanBeUsed(
    oldParams: GetTalentsForSourcingDto,
    newParams: GetTalentsForSourcingDto
  ): boolean {
    if (!this.getOldBaseParamsCanBeUsed(oldParams, newParams)) {
      return false;
    }
    const oldGroups = oldParams.requirementGroups;
    const newGroups = newParams.requirementGroups;
    return (
      !!oldGroups.length &&
      oldGroups.every(og => this.groupIsHandled(og, newGroups))
    );
  }

  private static getOldBaseParamsCanBeUsed(
    oldParams: GetTalentsForSourcingDto,
    newParams: GetTalentsForSourcingDto
  ): boolean {
    if (
      oldParams.baseInfo.acceptsFullRemote !==
      newParams.baseInfo.acceptsFullRemote
    ) {
      return false;
    }
    if (oldParams.baseInfo.roleId !== newParams.baseInfo.roleId) {
      return false;
    }
    if (
      !arraysAreEqual(
        oldParams.baseInfo.offices,
        newParams.baseInfo.offices,
        (a, b) =>
          `${a.areaId}|${a.latitude}|${a.longitude}` ===
          `${b.areaId}|${b.latitude}|${b.longitude}`
      )
    ) {
      return false;
    }

    if (
      oldParams.baseInfo.includesTravel !== newParams.baseInfo.includesTravel
    ) {
      return false;
    }
    if (oldParams.baseInfo.workFromHome !== newParams.baseInfo.workFromHome) {
      return false;
    }
    if (
      !arraysAreEqual(
        oldParams.baseInfo.rolesResponsibilities,
        newParams.baseInfo.rolesResponsibilities,
        (a, b) => a === b
      )
    ) {
      return false;
    }
    return true;
  }

  private static groupIsHandled(
    oldGroup: GetTalentsForSourcingRequirementGroupDto,
    newGroups: GetTalentsForSourcingRequirementGroupDto[]
  ): boolean {
    const newSingleReqs = newGroups.reduce(
      (acc: GetTalentsForSourcingRequirementDto[], g) => {
        if (g.requirements.length === 1) {
          return [...acc, g.requirements[0]!];
        }
        return acc;
      },
      []
    );

    const isSingleGroup = oldGroup.requirements.length === 1;

    if (isSingleGroup) {
      return this.requirementIsHandled(
        oldGroup.requirements[0]!,
        newSingleReqs
      );
    }
    return oldGroup.requirements.some(oldReq =>
      newGroups.some(
        ng =>
          this.requirementIsHandled(oldReq, ng.requirements) &&
          ng.requirements.every(nr =>
            oldGroup.requirements.some(or2 =>
              this.requirementIsHandled(or2, [nr])
            )
          )
      )
    );
  }

  private static requirementIsHandled(
    oldReq: GetTalentsForSourcingRequirementDto,
    newReqs: GetTalentsForSourcingRequirementDto[]
  ): boolean {
    switch (oldReq.type) {
      case JobOfferRequirementType.BranchNew:
        return newReqs.some(
          newReq =>
            newReq.type === oldReq.type &&
            newReq.value.id === oldReq.value.id &&
            newReq.value.value >= oldReq.value.value
        );
      case JobOfferRequirementType.EducationDomain:
        return newReqs.some(
          newReq => newReq.type === oldReq.type && newReq.value === oldReq.value
        );
      case JobOfferRequirementType.EducationLevel:
        return newReqs.some(
          newReq => newReq.type === oldReq.type && newReq.value >= oldReq.value
        );
      case JobOfferRequirementType.Language:
        return newReqs.some(
          newReq => newReq.type === oldReq.type && newReq.value === oldReq.value
        );
      case JobOfferRequirementType.Responsibility:
        return newReqs.some(newReq => {
          if (newReq.type !== oldReq.type) {
            return false;
          }
          const newResp = newReq.value.responsibility;
          const newLevel = newReq.value.level;
          const oldResp = oldReq.value.responsibility;
          const oldLevel = oldReq.value.level;

          return newResp === oldResp && newLevel >= oldLevel;
        });
      case JobOfferRequirementType.Salary:
        return newReqs.some(newReq => {
          return (
            newReq.type === oldReq.type &&
            newReq.value.baseSalary <= oldReq.value.baseSalary &&
            newReq.value.bonus <= oldReq.value.bonus
          );
        });
      case JobOfferRequirementType.School:
        return newReqs.some(
          tr => tr.type === oldReq.type && tr.value === oldReq.value
        );
      case JobOfferRequirementType.Skill:
        return newReqs.some(
          tr => tr.type === oldReq.type && tr.value === oldReq.value
        );
      case JobOfferRequirementType.Task:
        return newReqs.some(
          tr => tr.type === oldReq.type && tr.value === oldReq.value
        );
      case JobOfferRequirementType.WorkRole:
      case JobOfferRequirementType.EducationDomainsNew:
      case JobOfferRequirementType.SchoolsNew:
        return newReqs.some(
          newReq =>
            newReq.type === oldReq.type &&
            newReq.value.every(newRole => oldReq.value.includes(newRole))
        );
      case JobOfferRequirementType.YearsOfWorkExperience:
        return newReqs.some(
          newReq => newReq.type === oldReq.type && newReq.value >= oldReq.value
        );
      case JobOfferRequirementType.MaxYearsOfExperience:
        return newReqs.some(
          newReq => newReq.type === oldReq.type && newReq.value <= oldReq.value
        );
    }
  }
}
