import { render, staticRenderFns } from "./NotUpToDateLinkedInModal.vue?vue&type=template&id=31c94726&"
import script from "./NotUpToDateLinkedInModal.vue?vue&type=script&lang=ts&"
export * from "./NotUpToDateLinkedInModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./NotUpToDateLinkedInModal.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5Ctalent%5CmyProfile%5CNotUpToDateLinkedInModal.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VForm,VIcon})
