










































import { Vue, Component } from "vue-property-decorator";
import LinkedInEmploymentListItem from "../LinkedInEmploymentListItem.vue";
import TalentModalWrapper from "~/components/common/TalentModalWrapper.vue";
import { getEmploymentWithIdAndText } from "~/helpers/linkedInTalentHelper";
import { talentProfileStore } from "~/store";

export type NotUpToDateLinkedInModalModalResult =
  | {
      type: "Saved";
    }
  | {
      type: "Cancel";
    };

@Component({
  components: {
    TalentModalWrapper,
    LinkedInEmploymentListItem,
  },
})
export default class NotUpToDateLinkedInModalModal extends Vue {
  dialog = false;
  resolve: ((v: NotUpToDateLinkedInModalModalResult) => void) | null = null;

  open(): Promise<NotUpToDateLinkedInModalModalResult> {
    this.dialog = true;

    this.$refs.editForm && this.$refs.editForm.resetValidation();

    return new Promise<NotUpToDateLinkedInModalModalResult>(resolve => {
      this.resolve = resolve;
    });
  }

  get employmentsToAdd() {
    return talentProfileStore.linkedInEmploymentsAddedAfterLatestEmployment.map(
      getEmploymentWithIdAndText
    );
  }

  handleEmploymentSelected(employmentId: string) {
    this.$router.push(
      this.localePath({
        name: "talent-myExperience-employments",
        query: {
          employmentId: "asd",
        },
      })
    );

    setTimeout(() => {
      this.$router.push(
        this.localePath({
          name: "talent-myExperience-employments",
          query: {
            employmentId,
          },
        })
      );

      if (this.resolve) {
        this.resolve({
          type: "Cancel",
        });
      }
      this.dialog = false;
    }, 500);
  }

  $refs!: Vue["$refs"] & {
    editForm: any;
  };

  cancel() {
    if (this.resolve) {
      this.resolve({
        type: "Cancel",
      });
    }
    this.dialog = false;
  }

  save() {
    if (this.$refs.editForm.validate()) {
      if (this.resolve) {
        this.resolve({
          type: "Saved",
        });
      }
      this.dialog = false;
    }
  }
}
