import classifyPoint from "robust-point-in-polygon";
import { getDistance } from "geolib";

import locationsFromJson from "./locations.json";
import { isSomething } from "./filterHelpers";
import { TalentLocation } from "~/models/types";
export const allLocationsFromJson: {
  id: string;
  polygon: [number, number][];
  size?: number;
}[] = locationsFromJson.map((x: any) => ({
  id: x.id,
  polygon: x.polygon,
  size: x.size,
}));

export const getLocationsWithPolygonsAndSize = (
  loadedLocations: TalentLocation[]
): {
  id: string;
  polygon: [number, number][];
  size?: number;
}[] =>
  loadedLocations
    .filter(x => x.locationType !== "Area")
    .map(x => {
      return !x.geometry?.coordinates[0]
        ? null
        : {
            id: x.id,
            polygon: x.geometry.coordinates[0],
            size: x.size ?? undefined,
          };
    })
    .filter(isSomething);

export const getCoordinatesAreWithin = (v: {
  polygon: [number, number][];
  point: [number, number];
}): boolean => classifyPoint(v.polygon, v.point) <= 0;

export const polygonContainsOtherPolygon = (v: {
  polygon: [number, number][];
  otherPolygon: [number, number][];
}): boolean => {
  const pointsInside = v.otherPolygon.filter(point =>
    getCoordinatesAreWithin({ polygon: v.polygon, point })
  );

  if (pointsInside.length === 0) {
    return false;
  }

  if (v.otherPolygon.length === 0) {
    return false;
  }

  const isAtleas90PercentInside =
    pointsInside.length / v.otherPolygon.length >= 0.9;

  return isAtleas90PercentInside;
};

export const getDistanceBetweenPointsInMeters = (v: {
  point1: [number, number];
  point2: [number, number];
}): number => getDistance(v.point1, v.point2, 1);

export const getShortestDistanceBetweenPolygonsInMeters = (v: {
  polygon1: [number, number][];
  polygon2: [number, number][];
}): number =>
  v.polygon1.some(point =>
    getCoordinatesAreWithin({ polygon: v.polygon2, point })
  )
    ? 0
    : v.polygon2.some(point =>
        getCoordinatesAreWithin({ polygon: v.polygon1, point })
      )
    ? 0
    : v.polygon1.reduce((acc, point1) => {
        const minDistance = v.polygon2.reduce((acc, point2) => {
          const distance = getDistanceBetweenPointsInMeters({ point1, point2 });
          return Math.min(acc, distance);
        }, Infinity);

        return Math.min(acc, minDistance);
      }, Infinity);

export const getPolygonPrinted = (polygon: [number, number][]): string => {
  return polygon.map(p => p.join(",")).join("\n");
};

const getSizeSum = (
  allLocations: { id: string; size?: number | null }[],
  locationIds: string[]
) =>
  allLocations
    .filter(x => locationIds.includes(x.id))
    .reduce((acc, x) => (x.size ? acc + x.size : acc), 0);

export const getLocationTree = (v: {
  allLocations: {
    id: string;
    polygon?: [number, number][] | null;
    size?: number | null;
  }[];
}) => {
  const getText = (locationIds: string[]) =>
    locationIds
      .map(lid => lid + " " + getSizeSum(v.allLocations, [lid]))
      .join(" | ") +
    (locationIds.length === 1
      ? ""
      : ` (tot: ${getSizeSum(v.allLocations, locationIds)})`);

  return v.allLocations
    .map(location => ({
      id: location.id,
      children: v.allLocations
        .filter(x => x.id !== location.id)
        .filter(
          x =>
            location.polygon &&
            x.polygon &&
            polygonContainsOtherPolygon({
              polygon: location.polygon,
              otherPolygon: x.polygon,
            })
        )
        .map(x => x.id),
    }))
    .filter(x => x.children.length > 0)
    .map(x => ({ id: getText([x.id]), children: getText(x.children) }));
};
