import axios from "axios";

const runGritifyApiOnTest = false;
const runPublicOnTest = true;
const runSourcingRobotOnTest = true;

export const gritifyApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://webapi.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runGritifyApiOnTest
    ? "https://gritfy-api-test.azurewebsites.net/api"
    : "http://localhost:7071/api";

export const publicApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://public.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://public-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runPublicOnTest
    ? "https://gritify-publicapi-test.azurewebsites.net/api"
    : "http://localhost:7072/api";

export const sourcingRobotApiUrl =
  process.env.ENVIRONMENT === "Production"
    ? "https://sourcingrobot.gritify.io/api"
    : process.env.ENVIRONMENT === "Stage"
    ? "https://webapi-stage.gritify.io/api"
    : process.env.ENVIRONMENT === "Test" || runSourcingRobotOnTest
    ? "https://gritify-sourcingrobot-test.azurewebsites.net/api"
    : "http://localhost:7073/api";

export const getDateFromUtcString = (utcString: string | null): Date | null => {
  if (!utcString) {
    return null;
  }
  return new Date(utcString);
};

export const postToGritifyApi = <TResult>(
  urlPart: string,
  token: string,
  params: any
) =>
  axios
    .post<TResult>(`${gritifyApiUrl}/${urlPart}`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(x => x.data);

export const getFromGritifyApi = <TResult>(
  urlPart: string,
  token: string,
  params?: any
) =>
  axios
    .get<TResult>(`${gritifyApiUrl}/${urlPart}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(x => x.data);
