import * as A from "fp-ts/Array";
import * as O from "fp-ts/Option";
import { lookup } from "fp-ts/Map";

import {
  Monoid as StringMonoid,
  Eq as StringEq,
  Ord as StringOrd,
} from "fp-ts/string";
import { pipe } from "fp-ts/lib/function";
import FilterHelpers from "./filterHelpers";
import { LangObject, ThingToLoad } from "~/models/types";

export const getListWithRemovedItem = <T>(arr: T[]) => (
  isEquel: (a: T, b: T) => boolean
) => (item: T) => {
  return arr.filter(x => !isEquel(x, item));
};

export const getListWithUpsertedItem = <T>(arr: T[]) => (
  isEquel: (a: T, b: T) => boolean
) => (item: T): T[] => {
  const S = A.getUnionSemigroup<T>({ equals: isEquel });
  return S.concat([item], arr);
};

export const upsertItem = <T>(isEquel: (a: T, b: T) => boolean) => (
  item: T
) => (arr: T[]): T[] => {
  const S = A.getUnionSemigroup<T>({ equals: isEquel });
  return S.concat([item], arr);
};

export const groupBy = <T>(list: T[], keyGetter: (x: T) => string): T[][] => {
  const map = new Map<string, T[]>();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return [...map.values()];
};

export const getDistinct = <T>(arr: T[]): T[] => [...new Set(arr)];

export const isSomething = <T>(v: T | undefined | null): v is T =>
  v !== undefined && v !== null;

export const getFirstPrioritizing = <T>(predicate: (x: T) => boolean) => (
  list: T[]
): O.Option<T> =>
  pipe(
    list,
    A.findFirst(predicate),
    O.orElse(() => A.head(list))
  );

export const toggleInArray = <T>(arr: T[]) => (
  isEquel: (a: T, b: T) => boolean
) => (item: T) => {
  const existingItem = arr.find(x => isEquel(x, item));
  if (existingItem) {
    return arr.filter(x => !isEquel(x, item));
  }
  return [...arr, item];
};

export const removeSelectedNodeAndChildrenRecursive = <
  T extends { id: string; parentId: string | null }
>(
  list: T[],
  selectedId: string,
  removedIds: string[] = []
): T[] => {
  const children = list.filter(x => x.parentId === selectedId);
  if (children.length === 0) {
    return list.filter(x => x.id !== selectedId);
  }
  return [
    ...list.filter(x => x.id !== selectedId && !removedIds.includes(x.id)),
    ...children.flatMap(x =>
      removeSelectedNodeAndChildrenRecursive(list, x.id, [
        ...removedIds,
        ...children.map(x => x.id),
      ])
    ),
  ];
};

export const getIdsToRemoveIncludingChildren = (
  list: { id: string; childrenIds: string[] }[],
  selectedId: string
): string[] => {
  const removedIds: string[] = [selectedId];
  let listCopy = [...list];
  let parents = listCopy.filter(x => removedIds.includes(x.id));
  while (parents.length > 0) {
    removedIds.push(...parents.flatMap(x => x.childrenIds));
    parents = listCopy.filter(x => removedIds.includes(x.id));
    listCopy = listCopy.filter(x => !removedIds.includes(x.id));
  }

  return [...new Set(removedIds)];
};

export const getIsEqualProps = <T>(a: T) => (b: T) => (
  getProp: (x: T) => any
) => () => getProp(a) === getProp(b);

export const getAllIsTrue = (funcs: (() => boolean)[]): boolean => {
  const result: boolean = funcs.reduce((acc: boolean, f) => {
    return acc && f();
  }, true);
  return result;
};

export const arrContainsItem = <T>(arr: T[]) => (
  isEqual: (a: T, b: T) => boolean
) => (item: T): boolean => {
  return arr.some(x => isEqual(x, item));
};

export const arraysAreEqual = <T>(
  a: T[],
  b: T[],
  isEqual: (a: T, b: T) => boolean
) => {
  const arrBContainsItems = arrContainsItem(b)(isEqual);
  return a.length === b.length && a.every(arrBContainsItems);
};

export const getDistinctWhere = <T>(isEqual: (a: T, b: T) => boolean) => (
  list: T[]
): T[] => {
  const result: T[] = [];

  for (const item of list) {
    if (!result.some(x => isEqual(x, item))) {
      result.push(item);
    }
  }

  return result;
};

export const groupByIsEqual = <T>(isEqual: (a: T, b: T) => boolean) => (
  list: T[]
): T[][] => {
  const result: T[][] = [];

  for (const item of list) {
    const existingGroup = result.find(g => isEqual(g[0]!, item));

    if (existingGroup) {
      existingGroup.push(item);
    } else {
      result.push([item]);
    }
  }

  return result;
};

export const getIsNotPromise = <T>(v: T | Promise<T>): v is T =>
  (v as Promise<T>).then === undefined;

export const removeOptionals = A.compact;

export const joinStringsBy = A.intercalate(StringMonoid);

export const appendString = (stringToAppend: string) => (
  originalString: string
) => originalString + stringToAppend;

export const tryGetFromMap = lookup(StringEq);

export const getFromIds = <T extends { id: string }>(
  ids: string[],
  list: T[]
): T[] => list.filter(x => ids.includes(x.id));

export const getNumberFromInputValue = (v: number | null | string): number =>
  getNumberOrNullFromInputValue(v) ?? 0;

export const getNumberOrNullFromInputValue = (
  v: number | null | string
): number | null => {
  const resultOrNan = typeof v === "string" ? parseInt(v) : v;
  if (resultOrNan === null) {
    return null;
  }
  return isNaN(resultOrNan) ? null : resultOrNan;
};

export const tryGetValueFromPromiseMap = <T>(
  map: Map<string, T | "empty" | Promise<T | "empty"> | undefined>
) => (key: string | null): T | null => {
  if (!key) {
    return null;
  }
  const result = map.get(key) ?? null;

  if (result === null) {
    return null;
  }

  if (FilterHelpers.getIsNotPromise(result)) {
    if (result !== "empty") {
      return result;
    }
  }

  return null;
};
export const tryGetOptionFromPromiseMap = <T>(
  map: Map<string, T | "empty" | Promise<T | "empty"> | undefined>
) => (key: string | null): O.Option<T> => {
  if (!key) {
    return O.none;
  }
  const result = map.get(key) ?? null;

  if (result === null) {
    return O.none;
  }

  if (FilterHelpers.getIsNotPromise(result)) {
    if (result !== "empty") {
      return O.some(result);
    }
  }

  return O.none;
};

export const getCommaString = <T>(v: {
  keys: string[];
  list: T[];
  getKey: (x: T) => string;
  getText: (x: T) => string;
}): string => {
  return getListOfTexts(v).join(", ");
};

export const getListOfTexts = <T>(v: {
  keys: string[];
  list: T[];
  getKey: (x: T) => string;
  getText: (x: T) => string;
}): string[] => {
  return pipe(
    v.keys.flatMap(id => {
      const item = v.list.find(x => v.getKey(x) === id);
      return item ? [v.getText(item)] : [];
    }),
    A.uniq(StringEq)
  );
};
export const getListOfTextsOrDefault = <T>(v: {
  keys: string[];
  list: T[];
  getKey: (x: T) => string;
  getText: (x: T) => string;
  defaultString: string;
}): string[] =>
  pipe(getListOfTexts(v), list => (list.length > 0 ? list : [v.defaultString]));

export const getCommaStringFromRecord = <T>(v: {
  keys: string[];
  record: Record<string, T>;
  getText: (x: T) => string;
}): string => {
  return v.keys
    .flatMap(id => {
      const item = v.record[id];
      return item ? [v.getText(item)] : [];
    })
    .join(", ");
};

export const getListOfTextsFromRecord = <T>(v: {
  keys: string[];
  record: Record<string, T>;
  getText: (x: T) => string;
}): string[] => {
  return pipe(
    v.keys.flatMap(id => {
      const item = v.record[id];
      return item ? [v.getText(item)] : [];
    }),
    sortStringsAscending
  );
};

export const getCommaStringFromMap = <T>(v: {
  keys: string[];
  map: Map<string, T>;
  getText: (x: T) => string | null;
}): string => {
  return v.keys
    .flatMap(id =>
      pipe(
        v.map.get(id),
        O.fromNullable,
        O.chain(x => O.fromNullable(v.getText(x))),
        O.map(x => [x]),
        O.getOrElseW(() => [])
      )
    )
    .join(", ");
};

export const getCommaStringFromLangObjectMap = (v: {
  map: Map<string, LangObject | Promise<LangObject>>;
  lang: string;
  ids: string[];
}) =>
  pipe(
    v.ids,
    A.map(
      getTextByIdForLangObject({
        lang: v.lang,
        langObjectById: v.map,
      })
    ),
    A.compact
  ).join(", ");

const getTextForLang = (lang: string) => (langObject: LangObject) =>
  O.fromNullable(langObject.textDict[lang]);

export const getTextByIdForLangObject = (v: {
  langObjectById: Map<string, LangObject | Promise<LangObject>>;
  lang: string;
}) => (skillId: string): O.Option<string> =>
  pipe(
    v.langObjectById,
    tryGetFromMap(skillId),
    O.filter(getIsNotPromise),
    O.flatMap(getTextForLang(v.lang))
  );

export const getStringList = <T>(v: {
  list: T[];
  getString: (x: T) => string | null;
}): string[] =>
  pipe(
    v.list,
    A.map(x => O.fromNullable(v.getString(x))),
    A.compact
  );

export const getShortText = <T>(v: {
  list: T[];
  mapper: (v: T) => string;
}): string => {
  const firstItem = v.list[0];
  if (!firstItem) {
    return "";
  }
  const firstText = v.mapper(firstItem);

  const rest = v.list.length > 1 ? "" : "";

  return firstText + rest;
};

export const getJSONFormattedString = (v: any) => JSON.stringify(v, null, 2);

export const sortStringsAscending = (list: string[]): string[] =>
  pipe(list, A.sort(StringOrd));

export const sortListBy = <T>(
  getPropFunctions: Array<{
    sortBy: (v: T) => string | number | boolean | (Date | null);
    desc?: boolean;
  }>
) => (items: T[]): T[] => {
  return [...items].sort((a, b) => {
    for (let index = 0; index < getPropFunctions.length; index++) {
      const x = getPropFunctions[index]!;
      const aProp = x.sortBy(a);
      const bProp = x.sortBy(b);
      if (aProp === null || bProp === null) {
        return aProp === bProp
          ? 0
          : aProp === null
          ? !x.desc
            ? -1
            : 1
          : !x.desc
          ? 1
          : -1;
      }

      if (typeof aProp === "string" && typeof bProp === "string") {
        if (aProp.toLowerCase() < bProp.toLowerCase()) {
          return !x.desc ? -1 : 1;
        }
        if (aProp.toLowerCase() > bProp.toLowerCase()) {
          return !x.desc ? 1 : -1;
        }
      } else {
        if (aProp < bProp) {
          return !x.desc ? -1 : 1;
        }
        if (aProp > bProp) {
          return !x.desc ? 1 : -1;
        }
      }
    }

    return 0;
  });
};

const tryGetForLang = (lang: string) => (x: Record<string, string>) =>
  O.fromNullable(x[lang] ?? x.sv);

const getMonthsString = (v: { date: Date; lang: string }): string => {
  const monthNumber = v.date.getMonth();
  const months: Record<string, string>[] = [
    {
      en: "Jan",
      sv: "Jan",
    },
    {
      en: "Feb",
      sv: "Feb",
    },
    {
      en: "Mar",
      sv: "Mar",
    },
    {
      en: "Apr",
      sv: "Apr",
    },
    {
      en: "May",
      sv: "Maj",
    },
    {
      en: "Jun",
      sv: "Jun",
    },
    {
      en: "Jul",
      sv: "Jul",
    },
    {
      en: "Aug",
      sv: "Aug",
    },
    {
      en: "Sep",
      sv: "Sep",
    },
    {
      en: "Oct",
      sv: "Okt",
    },
    {
      en: "Nov",
      sv: "Nov",
    },
    {
      en: "Dec",
      sv: "Dec",
    },
  ];

  return pipe(
    months,
    A.lookup(monthNumber),
    O.chain(tryGetForLang(v.lang)),
    O.getOrElse(() => monthNumber.toString())
  );
};

export const getYearMonthString = (x: Date | null): string => {
  if (!x) {
    return "";
  }
  const year = x?.getFullYear();
  const month = (x.getMonth() + 1).toString().padStart(2, "0");

  return `${year}-${month}`;
};

export const getDateStringWithMonthName = (v: {
  date: Date;
  lang: string;
}): string => {
  const day = v.date.getDate();

  const month = getMonthsString(v);

  const year = v.date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const getYearAndMonthStringPretty = (x: {
  date: Date | null;
  lang: string;
}): string => {
  if (!x.date) {
    return "";
  }
  const year = x.date.getFullYear();
  const month = x.date.getMonth();

  if (month === 0) {
    return year.toString();
  }

  return `${getMonthsString({
    date: x.date,
    lang: x.lang,
  })} ${year}`;
};

const getMinutesString = (minutes: number): string => {
  return minutes < 10 ? `0${minutes}` : minutes.toString();
};

export const getTimeString = (v: { date: Date; lang: string }): string => {
  const hours = v.date.getHours();

  const minutes = v.date.getMinutes();

  if (v.lang === "sv") {
    return `kl ${hours}:${getMinutesString(minutes)}`;
  }

  return `${hours}:${getMinutesString(minutes)}`;
};

export const getTimeDateString = (v: { date: Date; lang: string }): string => {
  return `${getTimeString(v)}, ${getDateStringWithMonthName(v)}`;
};

export const getListOrDefault = (defaultString: string) => (
  list: string[]
): string[] => (list.length > 0 ? list : [defaultString]);

export const findById = <T extends { id: string }>(id: string | null) => (
  list: T[]
): O.Option<T> =>
  pipe(
    list,
    A.findFirst(x => x.id === id)
  );

export const tryGetById = <T extends { id: string }>(arr: T[]) => (
  id: string
): O.Option<T> =>
  pipe(
    arr,
    A.findFirst(x => x.id === id)
  );

export const getPromiseOrNullIfTimeout = <T>(v: {
  promise: Promise<T>;
  timeout: number;
}): Promise<T | null> => {
  return new Promise(resolve => {
    const timeout = setTimeout(() => {
      resolve(null);
    }, v.timeout);

    v.promise.then(result => {
      clearTimeout(timeout);
      resolve(result);
    });
  });
};

export const getNumberWithMaxDecimals = (v: {
  number: number;
  maxDecimals: number;
}): string => {
  return v.number.toFixed(v.maxDecimals);
};

export const getThingOrNull = <T>(thing: ThingToLoad<T>): T | null =>
  pipe(
    thing,
    tryGetValueFromThing,
    O.getOrElseW(() => null)
  );

export const tryGetValueFromThing = <T>(x: ThingToLoad<T>): O.Option<T> => {
  switch (x.type) {
    case "loaded":
    case "loading":
      return x.value ? O.some(x.value) : O.none;
    case "notFetched":
      return O.none;
  }
};

export const tryGetThingFromRecord = <T>(
  map: Record<string, ThingToLoad<T>>
) => (key: string): O.Option<T> => {
  return pipe(
    O.fromNullable(map[key]),
    O.chain(x => tryGetValueFromThing(x))
  );
};

export const getThingOrNullFromRecord = <T>(
  map: Record<string, ThingToLoad<T>>,
  key: string
) => {
  return pipe(
    O.fromNullable(map[key]),
    O.chain(x => tryGetValueFromThing(x)),
    O.getOrElseW(() => null)
  );
};

export const getNumberRounded = (decimals: number) => (number: number) => {
  const multiplier = Math.pow(10, decimals);
  return Math.round(number * multiplier) / multiplier;
};

export const fixLargeNumbers = (value: number | string | null): string => {
  return (
    (value || "0")
      // .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  );
};

const dateIsToday = (date: Date, today: Date): boolean => {
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const dateIsYesterday = (date: Date, today: Date): boolean => {
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};

export const getDateAndTimeFromDate = ({
  date,
  locale,
  todayString,
  yesterdayString,
  today,
}: {
  date: Date;
  locale: string;
  todayString: string;
  yesterdayString: string;
  today: Date;
}): string => {
  // It should be like 19 Apr, 09:22
  const day = date.getDate().toString();
  const month = getStringWithFirstLetterCapitalized(
    date.toLocaleString(locale, { month: "short" })
  );
  const hours = date
    .getHours()
    .toString()
    .padStart(2, "0");
  const minutes = date
    .getMinutes()
    .toString()
    .padStart(2, "0");

  if (dateIsToday(date, today)) {
    return `${todayString}, ${hours}:${minutes}`;
  }

  if (dateIsYesterday(date, today)) {
    return `${yesterdayString}, ${hours}:${minutes}`;
  }

  return `${day} ${month}, ${hours}:${minutes}`;
};

export const getStringWithFirstLetterCapitalized = (str: string): string => {
  // If the string is empty, return it as it is
  if (!str) return str;

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const doAndLogReturnValue = <T, U>(
  logDescrioption: string,
  fn: (x: T) => U,
  input: T
): U => {
  const result = fn(input);
  console.log(logDescrioption, result);
  return result;
};

export const getFromQueryString = (
  query: Record<string, string | (string | null)[]>,
  str: string
): string | null => {
  const value = query[str];
  if (Array.isArray(value)) {
    return value[0] ?? null;
  }
  return value ?? null;
};
