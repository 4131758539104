import { render, staticRenderFns } from "./talentLayout.vue?vue&type=template&id=3cb8f3a0&scoped=true&"
import script from "./talentLayout.vue?vue&type=script&lang=ts&"
export * from "./talentLayout.vue?vue&type=script&lang=ts&"
import style0 from "./talentLayout.vue?vue&type=style&index=0&id=3cb8f3a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb8f3a0",
  null
  
)

/* custom blocks */
import block0 from "./talentLayout.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Clayouts%5CtalentLayout.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VAppBar,VBadge,VIcon,VList,VListItem,VListItemIcon,VListItemTitle,VMain,VNavigationDrawer,VSpacer})
