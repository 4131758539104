






























































import { Vue, Component, Prop } from "nuxt-property-decorator";
import UnhandledRecruitmentAlertSymbol from "../company/UnhandledRecruitmentAlertSymbol.vue";
import { authStore, jobbOfferStore, officesStore } from "~/store";
import { RecruitmentListItem } from "~/models/types";
import { getOfficeNameForRecruitment } from "~/helpers/OfficeHelper";

@Component({
  components: { UnhandledRecruitmentAlertSymbol },
})
export default class RecruitmentPickerMenu extends Vue {
  @Prop({ type: String, required: true })
  readonly recruitmentId!: string;

  get recruitmentFromList(): RecruitmentListItem | null {
    return (
      jobbOfferStore.recruitmentList.find(r => r.id === this.recruitmentId) ||
      null
    );
  }

  getOfficeName(rec: RecruitmentListItem): string {
    return getOfficeNameForRecruitment({
      allOffices: officesStore.items,
      rec,
    });
  }

  get recruitments(): {
    text: string;
    id: string;
    isRemoved: boolean;
    officeName: string;
    hasUnhandledCandidates: boolean;
  }[] {
    return [...jobbOfferStore.recruitmentListFiltered].map(r => {
      return {
        text: r.title,
        id: r.id,
        isRemoved: !!r.closedDate,
        officeName: this.getOfficeName(r),
        hasUnhandledCandidates:
          r.numberOfAcceptedTalents + r.numberOfTalentsNotHandled > 0,
      };
    });
  }

  $refs!: Vue["$refs"] & {};

  changeRecruitment(recruitmentId: string) {
    const path = this.localePath({
      name: "company-companyId-recruitments-recruitmentId",
      params: {
        recruitmentId,
        companyId: this.companyId!,
      },
    });

    this.$router.push(path);
  }

  get isRemoved(): boolean {
    const recruitment = jobbOfferStore.recruitmentList.find(
      r => r.id === this.recruitmentId
    );
    if (!recruitment) {
      return false;
    }
    return !!recruitment.closedDate;
  }

  get title(): string {
    if (this.recruitmentFromList) {
      return this.recruitmentFromList.title;
    }
    return "";
  }

  get companyId() {
    return authStore.companyId;
  }
}
