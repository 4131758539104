
































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import {
  getCompanyNameForLinkedInEmployment,
  getDateStringForLinkedInEmployment,
  getEmploymentWithIdAndText,
  getImageUrlForLinkedInEmployment,
} from "~/helpers/linkedInTalentHelper";
import { LinkedInTalentInfoEmployment } from "~/models/linkedInTalentInfo";

@Component
export default class LinkedInEmploymentListItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly empInput!: LinkedInTalentInfoEmployment;

  @Prop({ type: Boolean, default: true })
  readonly showSelect!: boolean;

  @Prop({ type: String, default: null })
  readonly selectedId!: string | null;

  @Prop({ type: String, default: null })
  readonly selectButtonText!: string | null;

  get selectButtonTextInternal() {
    return this.selectButtonText || this.$t("Välj").toString();
  }

  get emp() {
    return getEmploymentWithIdAndText(this.empInput);
  }

  @Emit("selected")
  selectedEmit(id: string) {
    return id;
  }

  getImageUrl(emp: LinkedInTalentInfoEmployment): string | null {
    return getImageUrlForLinkedInEmployment(emp);
  }

  getCompanyName(emp: LinkedInTalentInfoEmployment): string | null {
    return getCompanyNameForLinkedInEmployment(
      emp,
      this.$t("Egenföretagare").toString()
    );
  }

  getDateString(emp: LinkedInTalentInfoEmployment): string | null {
    return getDateStringForLinkedInEmployment(
      emp,
      this.$i18n.locale,
      this.$t("nu").toString()
    );
  }
}
