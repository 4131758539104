import { isSomething } from "./CommonHelperFunctions";
import { Office, SavedOrNewOffice } from "~/models/types";
import { JobbOfferBaseInfo } from "~/models/JobbOfferBaseInfo";

export default class OfficeHelper {
  static getOfficeGoogleMapsImageForOffice(o: {
    latitude: number;
    longitude: number;
  }): string {
    return `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyA13WtlG66mirqvAuJOsLVdjZT9T8gI47I&center=${o.latitude},${o.longitude}&zoom=11&markers=${o.latitude},${o.longitude}&size=800x450`;
  }
}

export const getOfficeNameForRecruitment = (v: {
  rec: { offices: { id: string }[] };
  allOffices: { id: string; name: string; city: string }[];
}): string => {
  if (v.rec.offices.length === 0) {
    return "Remote";
  }
  const officeNames = v.rec.offices.flatMap(o => {
    const office = v.allOffices.find(x => x.id === o.id);
    return office ? [office.name] : [];
  });

  if (officeNames.length === 0) {
    return "";
  }

  if (officeNames.length === 1) {
    return officeNames[0]!;
  }

  if (officeNames.length === 2) {
    return `${officeNames[0]!}, ${officeNames[1]!}`;
  }

  return `${officeNames[0]!} +${officeNames.length - 1}`;
};

export const getCityNameForRecruitment = (
  baseInfo: JobbOfferBaseInfo
): string =>
  [
    ...new Set([
      baseInfo.offices.map(o => o.city),
      !baseInfo.offices.length || baseInfo.acceptsFullRemote ? "Remote" : null,
    ]),
  ]
    .filter(isSomething)
    .join(" / ");

export const getOfficeFromSavedOrNewOffice = (v: SavedOrNewOffice): Office => {
  if (v.type === "Saved") {
    return v.office;
  }

  const tempOffice = v.office;
  return {
    areaId: "",
    city: tempOffice.city,
    country: tempOffice.country,
    description: tempOffice.description,
    id: `new-${tempOffice.latitude}-${tempOffice.longitude}`,
    isRemoved: false,
    latitude: tempOffice.latitude,
    longitude: tempOffice.longitude,
    name: tempOffice.name,
    officeImage: null,
    postalCode: tempOffice.postalCode,
    streetAddress: tempOffice.streetAddress,
  };
};
