import { pipe } from "fp-ts/lib/function";
import { filter, map as aMap } from "fp-ts/lib/Array";
import { chain, fromNullable, getOrElseW, map } from "fp-ts/lib/Option";
import {
  getDistinctWhere,
  getYearAndMonthStringPretty,
  getYearMonthString,
  isSomething,
  sortListBy,
  tryGetValueFromThing,
} from "./CommonHelperFunctions";
import SortHelper from "./sortHelper";
import {
  LinkedInTalentInfoEmployment,
  LinkedInCompany,
  LinkedInTalentInfo,
} from "~/models/linkedInTalentInfo";
import {
  EmploymentSaved,
  EmploymentPeriod,
  EmploymentType,
  ThingToLoad,
  DateReplacer,
} from "~/models/types";

import { ListObjectNullable } from "~/models/ListObjectNullable";

import { talentProfileStore } from "~/store";
import { getDateFromUtcString } from "~/services/ServiceHelper";

const getEmploymentPeriod = (startDate: Date, endDate: Date | null) => {
  return {
    fromYear: getYearMonthString(startDate),
    toYear: endDate ? getYearMonthString(endDate) : null,
  };
};

const byLatestEmployment = ():
  | ((a: EmploymentPeriod, b: EmploymentPeriod) => number)
  | undefined => {
  return (a: EmploymentPeriod, b: EmploymentPeriod) =>
    SortHelper.byLatestEmploymentWithNow(a, b, new Date());
};

export const getLatestLinkedInEmployment = (
  talent: LinkedInTalentInfo
): LinkedInTalentInfo["employments"][0] | null => {
  type EmpWithPeriod = EmploymentPeriod & {
    emp: LinkedInTalentInfo["employments"][0];
  };
  const emps: EmpWithPeriod[] = talent.employments.flatMap(e => {
    if (!e.startDate) {
      return [];
    }

    return [
      {
        ...getEmploymentPeriod(e.startDate, e.endDate),
        emp: e,
      },
    ];
  });

  return emps.sort(byLatestEmployment())[0]?.emp ?? null;
};

export const getFromLinkedInCompany = (
  x: LinkedInCompany
): ListObjectNullable => {
  switch (x.type) {
    case "FromDb":
      return {
        id: x.companyId,
        text: x.name,
      };
    case "ToSave":
      return {
        id: null,
        text: x.name,
      };
    case "OnlyName":
      talentProfileStore.loadLinkedInCompanies({
        text: x.name,
      });
      return {
        id: null,
        text: x.name,
      };
  }
};

export const getEmploymentType = (
  linkedInEmpType: LinkedInTalentInfo["employments"][0]["type"]
): EmploymentType => {
  switch (linkedInEmpType) {
    case "FullTime":
      return EmploymentType.Employee;
    case "SelfEmployed":
      return EmploymentType.SelfEmployed;
    case "Internship":
      return EmploymentType.Intern;
  }
};

export const getNumberOfYears = (
  startDate: Date | null,
  endDate: Date | null,
  now: Date
): 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | null => {
  if (!startDate) {
    return 0;
  }

  const end = endDate ?? now;
  const result = end.getFullYear() - startDate.getFullYear();
  if (result < 1) {
    return 0;
  }

  if (result > 9) {
    return 9;
  }
  switch (result) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    case 6:
      return 6;
    case 7:
      return 7;
    case 8:
      return 8;
    case 9:
      return 9;
    default:
      return 9;
  }
};

export const getProfileIdFromUrl = (url: string): string => {
  const partsToRemoveFromUrl = [
    "http://",
    "https://",
    "www.",
    "linkedin.com/in/",
    "/",
  ];

  const result = partsToRemoveFromUrl.reduce((acc, part) => {
    return acc.replaceAll(part, "");
  }, decodeURI(url));

  return result;
};

export const getEmploymentWithIdAndText = (
  v: LinkedInTalentInfoEmployment
) => ({
  ...v,
  id: getLinkedInEmpId(v),
  text: getLinkedInEmpId(v),
  value: getLinkedInEmpId(v),
});

export const getSelectableLinkedInEmployments = (v: {
  employments: LinkedInTalentInfoEmployment[];
  existingEmployments: EmploymentSaved[];
}) => {
  return pipe(
    v.employments,
    filter(x => !v.existingEmployments.some(y => getIsEqual(x, y))),
    aMap(getEmploymentWithIdAndText),
    getDistinctWhere((a, b) => a.id === b.id)
  );
};

export const getLinkedInEmploymentsAddedAfterLatestEmployment = (v: {
  linkedInTalentInfo: LinkedInTalentInfo | null;
  existingEmployments: EmploymentSaved[];
}) => {
  if (!v.linkedInTalentInfo) {
    return [];
  }

  const latestStartDate = pipe(
    v.existingEmployments
      .map(x => getDateFromYearMonthString(x.fromYear))
      .filter(isSomething),
    sortListBy([
      {
        sortBy: x => x,
        desc: true,
      },
    ])
  )[0];

  if (!latestStartDate) {
    return v.linkedInTalentInfo.employments;
  }

  return v.linkedInTalentInfo.employments.filter(x =>
    pipe(x, getIsLaterThan(latestStartDate))
  );
};

const getIsLaterThan = (latestStartDate: Date) => (
  x: LinkedInTalentInfoEmployment
) =>
  pipe(
    fromNullable(x.startDate),
    map(linkedInStartDate => linkedInStartDate > latestStartDate),
    getOrElseW(() => false)
  );

const getIsEqual = (a: LinkedInTalentInfoEmployment, b: EmploymentSaved) => {
  if (getPeriodStringFromDates(a) !== getPeriodStringFromEmploymentSaved(b)) {
    return false;
  }

  if (a.title !== b.title.text) {
    return false;
  }

  if (a.type === "SelfEmployed") {
    return b.employmentType === EmploymentType.SelfEmployed;
  }

  if (a.company.type === "FromDb" && b.company.id !== null) {
    return a.company.companyId === b.company.id;
  }

  return a.company.name === b.company.text;
};

const getPeriodStringFromDates = (v: {
  startDate: Date | null;
  endDate: Date | null;
}): string =>
  `${getYearMonthString(v.startDate)}-${getYearMonthString(v.endDate)}`;

const getDateFromYearMonthString = (v: string | null): Date | null => {
  if (!v) {
    return null;
  }

  const parts = v.split("-");
  if (parts.length !== 2) {
    return null;
  }

  const year = parseInt(parts[0]!);
  const month = parseInt(parts[1]!) - 1;

  return new Date(year, month);
};

const getPeriodStringFromEmploymentSaved = (v: EmploymentSaved): string =>
  getPeriodStringFromDates({
    startDate: getDateFromYearMonthString(v.fromYear),
    endDate: getDateFromYearMonthString(v.toYear),
  });

export const getLinkedInEmpId = (v: LinkedInTalentInfoEmployment): string => {
  return (
    (v.type !== "SelfEmployed"
      ? `${v.title} på ${v.company.name}`
      : `${v.title}`) + getPeriodStringFromDates(v)
  );
};

export const getLinkedInUrl = (
  linkedInTalentInfo: ThingToLoad<LinkedInTalentInfo | null>
) =>
  pipe(
    tryGetValueFromThing(linkedInTalentInfo),
    chain(fromNullable),
    map(x => "linkedin.com/in/" + x.profileId),
    getOrElseW(() => null)
  );

export const getImageUrlForLinkedInEmployment = (
  emp: LinkedInTalentInfoEmployment
): string | null => {
  switch (emp.type) {
    case "FullTime":
    case "Internship":
      switch (emp.company.type) {
        case "FromDb":
        case "ToSave":
          return emp.company.logoUrl;
        case "OnlyName":
          return null;
      }
      break;
    case "SelfEmployed":
      return null;
  }
};

export const getCompanyNameForLinkedInEmployment = (
  emp: LinkedInTalentInfoEmployment,
  selfEmploymedText: string
): string => {
  switch (emp.type) {
    case "FullTime":
    case "Internship":
      return emp.company.name;
    case "SelfEmployed":
      return selfEmploymedText;
  }
};

export const getDateStringForLinkedInEmployment = (
  emp: LinkedInTalentInfoEmployment,
  lang: string,
  nowString: string
): string | null => {
  if (emp.startDate && emp.endDate) {
    return `${getYearAndMonthStringPretty({
      date: emp.startDate,
      lang,
    })} - ${getYearAndMonthStringPretty({
      date: emp.endDate,
      lang,
    })}`;
  } else if (emp.startDate) {
    return `${getYearAndMonthStringPretty({
      date: emp.startDate,
      lang,
    })} - ${nowString}`;
  } else if (emp.endDate) {
    return `- ${getYearAndMonthStringPretty({
      date: emp.endDate,
      lang,
    })}`;
  }
  return null;
};

export const mapLinkedInTalentInfo = (
  dto: DateReplacer<LinkedInTalentInfo>
): LinkedInTalentInfo => {
  return {
    ...dto,
    education: dto.education
      ? {
          ...dto.education,
          startDate: getDateFromUtcString(dto.education.startDate),
          endDate: getDateFromUtcString(dto.education.endDate),
        }
      : null,
    employments: dto.employments.map(e => ({
      ...e,
      startDate: getDateFromUtcString(e.startDate),
      endDate: getDateFromUtcString(e.endDate),
    })),
  };
};
